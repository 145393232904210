/* eslint-disable import/prefer-default-export */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/icons/style.css';
import RootWrapper from './src/components/RootWrapper';

export const wrapRootElement = RootWrapper;
