import React from 'react';
import Helmet from 'react-helmet';

const RootWrapper: React.FC<{
  element: React.ReactElement;
}> = ({ element }) => (
  <>
    <Helmet>
      <link
        href="https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;500"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=PT+Mono&display=swap"
        rel="stylesheet"
      />
    </Helmet>
    {element}
  </>
);

export default RootWrapper;
